import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import 'moment/locale/pt-br'
import './style.scss'

moment.locale('pt-br')

function isUrl(s) {
  var regexp = /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
  return regexp.test(s);
}
function SmartLink(props) {
  return isUrl(props.url) ? 
        <a href={props.url} className={props.className} target="_blank"> 
          {props.source? "Leia em " + props.source + "↗" : props.content}
        </a> :
        <Link className={props.className} to={props.url}>
          {props.content}
        </Link>;
}

class Post extends React.Component {
  render() {
    const {
      title,
      date,
      category,
      external,
      description,
    } = this.props.data.node.frontmatter
    const { slug, categorySlug } = this.props.data.node.fields

    return (
      <div className="post">
        <div className="post__meta">
          <time
            className="post__meta-time"
            dateTime={moment(date).format('MMMM D, YYYY')}
          >
            {moment(date).format('MMMM YYYY')}
          </time>
          <span className="post__meta-divider" />
          <span className="post__meta-category" key={categorySlug}>
            <Link to={categorySlug} className="post__meta-category-link">
              {category}
            </Link>
          </span>
        </div>
        <h2 className="post__title">
          <SmartLink url={slug} className="post__title-link" content={title}/>
          {/* <Link className="post__title-link" to={slug}>
            {title}
          </Link> */}
        </h2>
        <p className="post__description">{description}</p>
        <SmartLink url={slug} className="post__readmore" 
                  content="Leia mais..." source={category}/>
        {/* <Link className="post__readmore" to={slug}>
          Leia {external} mais...
        </Link>
        {external} ninja */}
      </div>
    )
  }
}

export default Post
